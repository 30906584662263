// see https://www.firewatchgame.com/
window.addEventListener("scroll", function(event){
  var top = this.scrollY;
  var layers = document.getElementsByClassName("layer");
  var layer, speed, yPos;
  for (var i = 0; i < layers.length; i++) {
    layer = layers[i];
    speed = layer.getAttribute('data-speed');
    var yPos = -(top * speed / 100);
    layer.setAttribute('style', 'background-position: center ' + yPos + 'px');
  }
});

let items = document.getElementsByClassName("faq-item");
for (let i = 0; i < items.length; i++) {
  const item = items.item(i);
  item.addEventListener("click", function(element) {
    if (!item.classList.contains("open")) {
      for (let i = 0; i < items.length; i++) {
        const element = items.item(i);
        if (element.classList.contains("open")) {
          close(element);
        }
      }
      open(item);
    }
  });
}

function open(item) {
  item.classList.add("open");
  item.querySelector("span").innerHTML = "-";
}

function close(item) {
  item.classList.remove("open");
  item.querySelector("span").innerHTML = "+";
}